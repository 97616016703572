import { render, staticRenderFns } from "./PointAddList.vue?vue&type=template&id=242f5348&scoped=true&"
import script from "./PointAddList.vue?vue&type=script&lang=js&"
export * from "./PointAddList.vue?vue&type=script&lang=js&"
import style0 from "./PointAddList.vue?vue&type=style&index=0&id=242f5348&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242f5348",
  null
  
)

export default component.exports