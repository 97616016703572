<template>
  <div class="monitor-style">
    <el-table :data="tableData" style="width: 100%" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="47"> </el-table-column>
      <el-table-column prop="gatewayNum" label="所属网关" align="center">
        <template slot-scope="scope">
          {{ nullToDash(scope.row[scope.column.property]) || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="pointName" label="测点编号" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) || '--' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="nodeNumber" label="节点编号" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="openDtuDialog(scope.row)">{{ nullToDash(scope.row[scope.column.property]) || '--' }}</el-button>
        </template>
      </el-table-column>
      <!--fixme 将这个和下面的dialog封装成一个组件 -->
      <!-- 修改 测点下 DTU -->
      <el-dialog title="修改节点" width="420px" :visible.sync="dtuVisible" :before-close="handleCloseChangeDtu" :modal-append-to-body="true" :append-to-body="true" custom-class="sinDialog">
        <div style="line-height: 24px; margin: 0">请选择节点</div>
        <el-select style="padding-top: 15px; width: 100%" v-model="fkNodeId">
          <el-option v-for="item in rDtuList" :label="item.number" :value="item.pkNodeId" :key="item.pkNodeId"
            ><span style="float: left">{{ item.number }}</span> <span style="float: right; color: #8492a6; font-size: 13px">{{ item.gatewayNode }}</span>
          </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button class="el-button el-button--default el-button--small" @click="handleCloseChangeDtu">取消</el-button>
          <el-button class="el-button el-button--default el-button--small el-button--primary" @click="handleChangeDtu">确定</el-button>
        </span>
      </el-dialog>
      <el-table-column prop="createTime" label="创建日期" align="center" width="150">
        <template slot-scope="scope">
          {{ nullToDash(scope.row[scope.column.property]) | time }}
        </template>
      </el-table-column>
      <!-- 111 -->
      <el-table-column prop="state" label="运行状态" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="openRunStateDialog(scope.row)">{{ scope.row[scope.column.property] === 1 ? '登记' : scope.row[scope.column.property] === 2 ? '运行中' : scope.row[scope.column.property] === 3 ? '完工' : '--' }}</el-button>
        </template>
      </el-table-column>
      <!-- 运行状态 对话框 -->
      <el-dialog title="修改运行状态" width="420px" :visible.sync="runStateVisible" :before-close="handleCloseChangeRunState" :modal-append-to-body="true" :append-to-body="true" custom-class="sinDialog">
        <div style="line-height: 24px; margin: 0">请选择运行状态</div>
        <el-select style="padding-top: 15px; width: 100%" v-model="fkRunningStateId">
          <el-option v-for="item of runStateList" :label="item.state" :value="item.value" :key="item.value"> </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button class="el-button el-button--default el-button--small" @click="handleCloseChangeRunState">取消</el-button>
          <el-button class="el-button el-button--default el-button--small el-button--primary" @click="handleChangeRunState">确定</el-button>
        </span>
      </el-dialog>
      <!-- 运行状态 对话框end -->
      <el-table-column label="初始值(mm)" align="center" prop="startingValueOne">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="预警值(mm)" align="center" prop="warningValue">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="报警值(mm)" align="center" prop="alarmValue">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="安全状态" align="center" prop="safeState">
        <template slot-scope="scope">
          <span class="" v-if="scope.row[scope.column.property] === '预警'" style="font-size: 14px; color: #ffa700">{{ scope.row[scope.column.property] }}</span>
          <span class="" v-else-if="scope.row[scope.column.property] === '报警'" style="font-size: 14px; color: #fc011a">{{ scope.row[scope.column.property] }}</span>
          <span class="" v-else style="font-size: 14px; color: #0dbc79">{{ '安全' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <i class="el-icon-delete-solid" @click="handleDeletePoint(scope.row)" title="删除测点" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import alarmDialog from '../alarmDialog'
import pointsMixin from './Mixins/points'
export default {
  mixins: [pointsMixin],
  // components: {
  //   alarmDialog,
  // },
  data() {
    return {}
  },
  methods: {},
  created() {
    console.log('tableData11111111', this.tableData)
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/monitor-style.scss';
</style>
./V2_V2.5/Mixins/points
