<template>
  <div>
    <el-form label-width="110px" label-position="top">
      <el-form-item>
        <el-select v-model.number="stateId" placeholder="请选择">
          <el-option label="迁移中" :value="4" disabled></el-option>
          <el-option label="登记" :value="1"></el-option>
          <el-option label="运行中" :value="2"></el-option>
          <el-option label="完工" :value="3"
            ><span>完工------<span style="color: red">此操作将迁出所有节点</span></span></el-option
          >
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-row class="bt"><el-button @click="$parent.handleClose()">取消</el-button> <el-button type="primary" @click="open" :disabled="disabled">修改</el-button></el-row>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updateTaskState, removal } from '@/api/Task.js'
export default {
  data() {
    return {
      stateId: 1,
      disabled: false,
      msgProceed: null,
    }
  },
  props: {
    taskId: {
      type: Number,
    },
    state: {
      type: Number,
    },
  },
  methods: {
    open() {
      if (this.stateId === 3) {
        this.$confirm('此操作将迁出所有节点, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.handleUpdateRunState()
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消',
            })
            this.$parent.handleClose()
          })
      } else {
        this.handleUpdateRunState()
      }
    },
    // async updateRunState() {
    //   //
    //   this.disabled = true
    //   if (this.stateId === 3) {

    //     console.log
    //     const { data: testRes } = await findTaskByTaskId(this.taskId)
    //     if (testRes.data.list[0].state.pkTaskRunningStateId === 3
    //       || testRes.data.list[0].state.pkTaskRunningStateId === 4) {
    //       this.$message({
    //         message: '任务正在迁移中或已迁移完成',
    //         type: 'warning',
    //       })
    //       this.$parent.handleClose()
    //       this.$parent.$parent.findAllProject()
    //     } else {
    //          // 开启弹窗
    //       let msgProceed = this.$message({
    //         message: '正在迁移中，请耐心等待...',
    //         type: 'success',
    //         duration: 0,
    //       })

    //       const { data: res } = await updateTaskState(this.taskId, this.stateId)

    //        msgProceed.close()

    //       // 展示新提示
    //       this.$message({
    //         message: res.message,
    //         type: res.code === 200 ? 'success' : 'error',
    //       })

    //       this.disabled = false
    //       this.$parent.handleClose()
    //       this.$parent.$parent.findAllProject()
    //     }

    //     this.disabled = false
    //     return
    //   }

    //   const { data: res } = await updateTaskState(this.taskId, this.stateId)

    //   this.$message({
    //     message: res.message,
    //     type: res.code === 200 ? 'success' : 'error',
    //   })

    //   this.disabled = false
    //   this.$parent.handleClose()
    //   this.$parent.$parent.findAllProject()

    // },

    async handleUpdateRunState() {
      if (this.stateId === 3) {
        await this.updateTaskState()
        await this.removalFunc()
      } else {
        await this.updateTaskState()
      }
    },
    // 数据迁移
    async removalFunc() {
      const { data: res } = await removal(this.taskId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findAllProject()
    },
    // 修改任务状态
    async updateTaskState() {
      const { data: res } = await updateTaskState(this.taskId, this.stateId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.handleClose()
      this.$parent.$parent.findAllProject()
    },
  },
  created() {
    this.stateId = this.state ? this.state : 1
  },

  mounted() {},
}
</script>

<style scoped lang="scss">
.el-select {
  width: 300px;
}

.bt {
  text-align: right;
}
</style>
