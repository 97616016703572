<template>
  <div>
    <el-form label-width="80px" class="demo-ruleForm">
      <el-form-item label="预警值" prop="warning">
        <el-input v-model="warning"></el-input>
      </el-form-item>
      <el-form-item label="报警值" prop="alarm">
        <el-input v-model="alarm"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">批量设置</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { updatePointsAlarm } from '@/api/Point.js'
export default {
  data() {
    return {
      warning: null,
      alarm: null,
    }
  },
  props: {
    taskId: {
      type: Number,
    },
    itemType: {
      type: String,
    },
  },
  methods: {
    async submitForm() {
      if (window.isNaN(this.warning - 0) || window.isNaN(this.alarm - 0)) {
        this.$message({
          message: '非法输入',
          type: 'error',
        })
        this.$emit('handleClose')
        return
      }

      const { data: res } = await updatePointsAlarm(this.taskId, this.itemType, this.warning - 0, this.alarm - 0)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findAllPointFun()
      this.handleClose()
    },
    handleClose() {
      this.$emit('handleClose')
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
