<template>
  <div class="inner-table" ref="innerTable">
    <!-- fixme 将以下组件中重复内容添加mixin 并且将对runState的请求删掉 将runState传递过去即可 -->
    <component v-if="itemTableList.length > 0" :is="componentName" :tableData="itemTableList" :componentName="componentName" :item="tableRow.deviceProtocol" :runStateList="runState" :dtuList="dtuList" @refreshTable="getItemTable" :projectId="projectId"> </component>
    <div v-else class="message">
      {{ message }}
    </div>
  </div>
</template>

<script>
import HighMoldLaserHItem from './HighMoldLaserHItem.vue'
import HighMoldLaserVItem from './HighMoldLaserVItem.vue'
import HighMoldAxialFItem from './HighMoldAxialFItem.vue'
import HighMoldInclinationLItem from './HighMoldInclinationLItem.vue'

export default {
  components: {
    HighMoldLaserHItem,
    HighMoldLaserVItem,
    HighMoldAxialFItem,
    HighMoldInclinationLItem,
  },
  props: {
    tableRow: {
      type: Object,
    },
    projectId: {
      type: Number,
    },
    runState: {
      type: Array,
      default: () => {
        return []
      },
    },
    dtuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      itemTableList: [],
      protocol: null,
      message: '该监测组下无测点',
    }
  },
  computed: {
    // dtuListWithNull: function () {
    //   const result = JSON.parse(JSON.stringify(this.dtuList))
    //   result[result.length] = {
    //     nodeNumber: '无',
    //     pkNodeId: 0,
    //   }
    //   return result
    // },
    componentName: function () {
      console.log(this.tableRow.deviceProtocol)

      return this.tableRow.deviceProtocol
    },
  },
  watch: {
    '$props.tableRow.data'(e) {
      console.log(e)
      // this.itemTableList = this.$props.tableRow.data
      this.getTable()
    },
    // '$props.tableRow.data.node.fkRunningStateId'() {
    //   this.getTable()
    //   // console.log('eeeeeee', e)
    //   // this.itemTableList = this.$props.tableRow.data
    // },
  },
  methods: {
    getItemTable() {},
    getTable() {
      this.$props.tableRow.data.forEach((item) => {
        // item.relationship ? (item.fkRunningStateId = item.relationship.fkNodeRunningStateId) : (item.fkRunningStateId = null)

        switch (item.fkRunningStateId) {
          case 1:
            item.runState = '登记'
            break
          case 2:
            item.runState = '运行中'
            break
          case 3:
            item.runState = '完工'
            break
        }
        // item.node ? (item.runState = item.node.fkRunningStateId) : (item.fkRunningStateId = 0)
      })
      this.itemTableList = this.$props.tableRow.data
    },
  },
  created() {
    // this.itemTableList = this.$props.tableRow.data
    console.log('获取', this.dtuList)
    this.getTable()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
// .inner-table {
//   padding: 2px;
//   //   position: absolute;
// }
// .inner-table ::v-deep .el-table__expanded-cell {
//   padding: 0px 0px !important;
// }
.message {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
</style>
./V2_V2.5/HighMoldAxialFItem.vue./V2_V2.5/HighMoldInclinationLItem.vue./V2_V2.5/HighMoldLaserHItem.vue./V2_V2.5/HighMoldLaserVItem.vue
