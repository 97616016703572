<template>
  <div>
    <el-descriptions title="当前任务设备信息" :column="4">
      <template slot="extra">
        <!-- <el-button type="primary" size="small" style="margin-right: 10px">强制移除</el-button>
        <el-button type="primary" size="small" style="margin-right: 10px">报警器测试</el-button> -->
      </template>
      <el-descriptions-item label="设备编号">{{ soundObj ? soundObj.deviceNo : '无' }}</el-descriptions-item>
      <el-descriptions-item label="SIM卡号">{{ soundObj ? soundObj.simCard : '无' }}</el-descriptions-item>
      <el-descriptions-item label="端口">{{ soundObj ? soundObj.port : '无' }}</el-descriptions-item>
      <el-descriptions-item label="是否在用">
        {{ soundObj ? (soundObj.runningState === 0 ? '在用' : '未用') : '无设备' }}
      </el-descriptions-item>
    </el-descriptions>

    <h2 class="title">设备测试</h2>
    <div style="margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-bell" size="small" style="margin-right: 10px" :disabled="isDisabled" @click="operationFun(2)"> 开启报警</el-button>
      <el-button type="primary" icon="el-icon-bell" size="small" style="margin-right: 10px" :disabled="isDisabled" @click="operationFun(1)">开启预警</el-button>
      <el-button type="primary" icon="el-icon-bell" size="small" style="margin-right: 10px" :disabled="isDisabled" @click="operationFun(3)">关闭</el-button>
      <el-button type="danger" icon="el-icon-delete" size="small" style="margin-right: 10px" @click="delSoundFun" :disabled="isDisabled">强制移出</el-button>
    </div>

    <h2 class="title">设备列表</h2>
    <MyTable
      ref="myTable"
      :dataSource="dataSource"
      :columns="columns"
      border
      :loading="loading"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :highlightCurrentRow="true"
      @handleClick="handleClick"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    ></MyTable>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import { findSoundByHost, findSoundByTask, setSound, delSound, operation } from '@/api/Sound'

const columns = [
  {
    prop: 'deviceNo',
    label: '设备编号',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'simCard',
    label: 'SIM卡号',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'projectName',
    label: '当前任务',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'runningStateText',
    label: '是否可用',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'port',
    label: '在线状态',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'deviceSecret',
    label: '电量',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'productKey',
    label: '信号',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'set',
    label: '设置',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '应用',
      type: 'success',
      size: 'mini',
    },
  },
]
export default {
  props: {
    pkTaskId: { type: Number },
    hostId: { type: Number },
  },
  components: { MyTable },
  data() {
    return {
      currentPage: 0,
      pageSize: 10,
      total: 0,
      loading: true,
      dataSource: [],
      columns,
      value: 1,
      soundObj: {},
      isDisabled: true,
    }
  },
  methods: {
    async findSoundByHostFun() {
      this.loading = true
      const { data: res } = await findSoundByHost(this.hostId, this.currentPage, this.pageSize)

      res.data.list.forEach((item) => {
        item.projectName = item.task.projectName || '无'
        item.runningStateText = item.runningState === 0 ? '未用' : '在用'
        switch (item.state) {
          case 'ONLINE':
            item.stateText = '在线'
            break
          case 'OFFLINE':
            item.stateText = '离线'
            break
          case 'UNACTIVE':
            item.stateText = '未激活'
            break
          case 'DISABLE':
            item.stateText = '禁用'
            break
          case 'failed':
            item.stateText = '无效'
            break
        }
      })
      this.dataSource = res.data.list
      this.total = res.data.total
      this.loading = false
    },
    async findSoundByTaskFun() {
      const { data: res } = await findSoundByTask(this.pkTaskId)

      this.soundObj = res.data ? res.data : null

      this.isDisabled = this.soundObj ? false : true
    },
    async setSoundFun(id) {
      const { data: res } = await setSound(this.pkTaskId, id)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.init()
    },
    // 移除报警器
    async delSoundFun() {
      const { data: res } = await delSound(this.soundObj.pkAlarmId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.init()
    },
    async operationFun(id) {
      const { data: res } = await operation(this.pkTaskId, id)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.findSoundByHostFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findSoundByHostFun()
    },
    handleClick(row, type) {
      if (type === 'set') {
        console.log(row)
        this.setSoundFun(row.pkAlarmId)
      }
    },
    init() {
      this.findSoundByHostFun()
      this.findSoundByTaskFun()
    },
  },
  created() {
    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-descriptions-item__label {
  line-height: 32px;
}
::v-deep .el-descriptions__header {
  margin-bottom: 10px;
}

::v-deep .el-descriptions-item__container {
  line-height: 32px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
.title {
  color: #303133;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
