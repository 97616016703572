<template>
  <div class="pointConfig" style="margin-bottom: 20px">
    <div class="row" style="display: flex">
      <h2 style="line-height: 40px; font-size: 16px; font-weight: 600; color: #333">V2&V2.5测点配置:&nbsp;</h2>
      <el-button-group>
        <el-button type="primary" @click="handleClick('add', '新增测点')" icon="el-icon-circle-plus-outline"> 新增测点 </el-button>
        <el-button type="primary" @click="handleClick('addList', '批量新增')" icon="el-icon-circle-plus-outline"> 批量新增 </el-button>
        <el-button type="primary" @click="handleClick('editSpan')" icon="el-icon-refresh">修改轴力量程</el-button>
        <el-button type="primary" @click="handleClick('delList')" icon="el-icon-delete" :disabled="this.itemList.length <= 0">批量删除</el-button>
        <el-button type="success" @click="switchAllNode(0)">远程开启</el-button>
        <el-button type="danger" @click="switchAllNode(1)">远程关机</el-button>
      </el-button-group>
    </div>

    <el-table :data="tableData" class="tableBox" style="width: 100%" border @row-click="handleRowChange">
      <el-table-column type="expand">
        <template slot-scope="scope">
          <PointList ref="plist" :tableRow="scope.row" :projectId="projectId" :runState="runState" :dtuList="scope.row.nodeType === 'VF' ? VFNodeList : HLNodeList"></PointList>
        </template>
      </el-table-column>

      <el-table-column prop="deviceProtocolName" label="监测协议名称" align="center"> </el-table-column>
      <el-table-column prop="groupName" label="监测项目组名称" align="center"> </el-table-column>
      <el-table-column label="初始化" align="center" width="120px">
        <template slot-scope="scope">
          <el-button type="" icon="el-icon-odometer" size="mini" @click="pointInit(scope.row)" :disabled="scope.row.itemType === 'H'"></el-button>
        </template>
      </el-table-column>
      <el-table-column label="批量设置报警" align="center" width="120px">
        <template slot-scope="scope">
          <!-- <el-button type="" icon="el-icon-odometer" size="mini" @click="fun(scope)">初始化</el-button> -->
          <el-button type="" icon="el-icon-bell" size="mini" @click="setAlarmFun(scope.row)"></el-button>
          <!-- <el-button type="" icon="el-icon-delete" size="mini" @click="fun(scope)"></el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="新增测点" class="addPoint" center :visible.sync="dialogVisibleAdd" :before-close="handleClose" append-to-body>
      <PointAdd ref="add" v-if="dialogVisibleAdd" @handleClose="handleClose" :taskId="projectId"></PointAdd>
    </el-dialog>
    <el-dialog title="批量新增测点" class="addPoint" center :visible.sync="dialogVisibleAddList" :before-close="handleClose" append-to-body>
      <PointAddList ref="add" v-if="dialogVisibleAddList" @handleClose="handleClose" :taskId="projectId"></PointAddList>
    </el-dialog>

    <el-dialog title="修改dtu" width="420px" :visible.sync="dialogVisibleSpan" :before-close="handleClose" :modal-append-to-body="true" :append-to-body="true" custom-class="sinDialog">
      <div style="line-height: 24px; margin: 0">请选择dtu</div>
      <el-select style="padding-top: 15px; width: 100%" v-model="span">
        <el-option v-for="item in spanList" :label="item.span" :value="item.value" :key="item.value"> </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button class="el-button el-button--default el-button--small" @click="handleClose">取消</el-button>
        <el-button class="el-button el-button--default el-button--small el-button--primary" @click="handleChangeSpan">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="批量设置报警值" width="420px" class="alarm" center :visible.sync="dialogVisibleAlarm" :before-close="handleClose" append-to-body>
      <PointAlarm v-if="dialogVisibleAlarm" @handleClose="handleClose" :taskId="projectId" :itemType="itemType"> </PointAlarm>
    </el-dialog>
  </div>
</template>

<script>
import { findAllPoint, updateTaskSpan, updatePointsValue, delPointList } from '@/api/Point.js'
import { /* findAllNodeByTask, */ findAllNodeByTaskId, handleSwitchNode } from '@/api/Node.js'
import PointList from './PointList/index.vue'
import PointAdd from './PointAdd.vue'
import PointAddList from './PointAddList.vue'
import PointAlarm from './batchAlarm.vue'

export default {
  components: {
    PointList,
    PointAdd,
    PointAddList,
    PointAlarm,
  },
  props: {
    projectId: {
      type: Number,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibleAdd: false,
      dialogVisibleAddList: false,
      dialogVisiblePointList: false,
      dialogVisibleSpan: false,
      dialogVisibleAlarm: false,
      title: '',
      runState: [
        { value: 1, state: '登记' },
        { value: 2, state: '运行中' },
        { value: 3, state: '完工' },
      ],
      dtuList: [],
      HLNodeList: [],
      VFNodeList: [],
      itemList: [],

      tableData: [
        {
          deviceProtocolName: 'V_沉降监测',
          deviceProtocol: 'HighMoldLaserVItem',
          groupName: '沉降',
          itemType: 'V',
          nodeType: 'VF',
          data: [],
        },
        {
          deviceProtocolName: 'F_轴力监测',
          deviceProtocol: 'HighMoldAxialFItem',
          groupName: '轴力',
          itemType: 'F',
          nodeType: 'VF',
          data: [],
        },
        {
          deviceProtocolName: 'L_倾角监测',
          deviceProtocol: 'HighMoldInclinationLItem',
          groupName: '倾角',
          itemType: 'L',
          nodeType: 'HL',
          data: [],
        },
        {
          deviceProtocolName: 'H_水平位移监测',
          deviceProtocol: 'HighMoldLaserHItem',
          groupName: '位移',
          itemType: 'H',
          nodeType: 'HL',
          data: [],
        },
      ],
      tableData_V3: [
        {
          deviceProtocolName: 'V_沉降监测(versions:3.0)',
          deviceProtocol: 'HighMoldLaserVItem',
          groupName: '沉降',
          itemType: 'V',
          nodeType: 'VF',
          data: [],
        },
        {
          deviceProtocolName: 'F_轴力监测(versions:3.0)',
          deviceProtocol: 'HighMoldAxialFItem',
          groupName: '轴力',
          itemType: 'F',
          nodeType: 'VF',
          data: [],
        },
        {
          deviceProtocolName: 'L_倾角监测(versions:3.0)',
          deviceProtocol: 'HighMoldInclinationLItem',
          groupName: '倾角',
          itemType: 'L',
          nodeType: 'HL',
          data: [],
        },
        {
          deviceProtocolName: 'H_水平位移监测(versions:3.0)',
          deviceProtocol: 'HighMoldLaserHItem',
          groupName: '位移',
          itemType: 'H',
          nodeType: 'HL',
          data: [],
        },
      ],

      pointBol: false,
      form: {
        name: '',
        num: 0,
        protocol: '',
      },
      span: 5,
      spanList: [
        {
          span: '5T',
          value: 5,
        },
        {
          span: '10T',
          value: 10,
        },
      ],
    }
  },

  // // 计算属性
  // computed: {
  //   filterNodeList() {

  //   }
  // },
  methods: {
    async switchAllNode(switchNode) {
      const { data: res } = await handleSwitchNode({ taskId: this.projectId, switchNode })
      console.log(res)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
    },

    // 查询任务所有测点
    async findAllPointFun() {
      const { data: res } = await findAllPoint(this.projectId)

      this.tableData[1].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null

        return item.monitoringType === '轴力监测'
      })
      this.tableData[2].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null

        return item.monitoringType === '倾角监测'
      })
      this.tableData[3].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null

        return item.monitoringType === '位移监测'
      })
      this.tableData[0].data = res.data.filter((item) => {
        item.node ? (item.nodeNumber = item.node.nodeNumber) : null

        return item.monitoringType === '沉降监测'
      })
    },
    // 查询任务所有节点
    async findAllNodeByTaskFun() {
      const { data: res } = await findAllNodeByTaskId(this.projectId)
      console.log('123', res.data)

      this.nodeList = res.data

      this.VFNodeList = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
        if (item.nodeNumber.includes('V2.5')) {
          return item.nodeType !== 'HL'
        } else {
          return item.nodeType === 'VF'
        }
      })

      this.HLNodeList = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
        return item.nodeType === 'HL'
      })
    },
    // 修改轴力量程
    async handleChangeSpan() {
      const { data: res } = await updateTaskSpan(this.projectId, this.span)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findAllPointFun()
      this.handleClose()
    },
    async pointInit(e) {
      const { data: res } = await updatePointsValue(this.projectId, e.itemType)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      if (res.data.length > 0) {
        this.$message({
          message: '其中<span style="color:green"> ' + res.data + ' </span>配置失败，请检查测点是否有数据！！！',
          type: 'error',
          offset: 80,
          dangerouslyUseHTMLString: true,
        })
      }

      this.findAllPointFun()
      this.handleClose()
    },
    async delPointListFun() {
      const { data: res } = await delPointList(this.itemList.join())
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.itemList = []
      this.findAllPointFun()
      this.handleClose()
    },

    setAlarmFun(e) {
      this.itemType = e.itemType
      this.dialogVisibleAlarm = true
    },
    handleClose() {
      this.dialogVisibleAddList = this.dialogVisibleAdd = this.dialogVisibleTransfer = this.dialogVisiblePointList = this.dialogVisibleSpan = this.dialogVisibleAlarm = false
    },
    dialogShow(e) {
      if (e === '新增') {
        this.dialogVisiblePointList = this.dialogVisible = true
        this.title = '新增节点'
      }
      if (e === '迁移') {
        this.dialogVisibleTransfer = this.dialogVisible = true
        this.title = '批量配置节点'
      }
    },
    handleRowChange() {},
    handleClick(val, title) {
      console.log(val, title)
      switch (val) {
        case 'add':
          this.dialogVisibleAdd = true
          break
        case 'addList':
          this.dialogVisibleAddList = true
          break
        case 'delList':
          this.$confirm('是否批量删除如下选中测点, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.delPointListFun()
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: '已取消删除',
              })
            })
          break
        case 'del':
          this.dialogVisibleDel = true
          break
        case 'editSpan':
          this.dialogVisibleSpan = true
          break
        default:
          break
      }
    },
    handleSelectionChange(e) {
      this.itemList = []
      e.forEach((item) => {
        this.itemList.push(item.pkItemId)
      })
      console.log(this.itemList)
    },
  },
  created() {
    this.findAllPointFun()
    this.findAllNodeByTaskFun()
  },
  mounted() {
    //  react uni-app es6 ts
  },
}
</script>

<style scoped lang="scss">
.el-button-group {
  margin-bottom: 20px;
}

.pointConfig {
  background-color: #fff;
  border-radius: 8px;
}

.pointConfig ::v-deep .el-table__expanded-cell {
  padding: 0;
}

.addPoint {
  ::v-deep .el-dialog {
    width: 480px;
    height: 420px;
  }
}

.alarm {
  ::v-deep .el-dialog {
    height: 300px;
  }
}
</style>
./PointList/V2_V2.5/index.vue
