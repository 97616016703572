<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="测点编号" prop="pointName">
        <el-input v-model="ruleForm.pointName"></el-input>
      </el-form-item>

      <el-form-item label="测点类型" prop="monitoringType">
        <el-select v-model="ruleForm.monitoringType" placeholder="请选择测点类型">
          <el-option label="V-沉降监测" value="沉降监测"></el-option>
          <el-option label="F-轴力监测" value="轴力监测"></el-option>
          <el-option label="L-倾角监测" value="倾角监测"></el-option>
          <el-option label="H-水平位移监测" value="位移监测"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="节点" prop="fkNodeId">
            <el-select v-model="ruleForm.fkNodeId" placeholder="请选择节点">
              <el-option v-for="item in filterNodeList" :key="item.pkNodeId" :label="item.nodeNumber"
                :value="item.nodeNumber"></el-option>
          </el-select>

      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//  查询任务中的节点
import { addPoint } from '@/api/Point.js'
import { findAllNodeByTask } from '@/api/Node.js'
export default {
  data() {
    return {
      ruleForm: {
        pointName: null,
        // fkTaskId: null,
        fkNodeId: null,
        monitoringType: '沉降监测',
      },
      rules: {
        pointName: [{ required: true, message: '请输入测点编号', trigger: 'blur' }],
        monitoringType: [{ required: true, message: '请选择测点类型', trigger: 'blur' }],
        // pointName:[ { required: true, message: '请输入测点编号', trigger: 'blur' },]
      },
      nodeList: []
    }
  },

  // 计算属性
  computed: {
    filterNodeList() {
      if (this.ruleForm.monitoringType === '轴力监测') {
        const list = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
          if (item.nodeNumber.includes('V2.5')) {
            return item.nodeType === 'F'
          } else {
            return item.nodeType === 'VF'
          }
        })
        return list
      } else if (this.ruleForm.monitoringType === '沉降监测') {
        const list = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
          if (item.nodeNumber.includes('V2.5')) {
            return item.nodeType === 'V'
          } else {
            return item.nodeType === 'VF'
          }
        })
        return list
      } else {
        const list = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
          return item.nodeType === 'HL'
        })
        return list
      }
    }
  },
  props: {
    taskId: {
      type: Number,
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addPointFun()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 查询任务中的节点
    async findAllNodeByTaskFun() {
      const { data: res } = await findAllNodeByTask(this.taskId)
      console.log('任务节点列表', res.data)

      this.nodeList = res.data


      // this.filterNodeList(this.ruleForm.monitoringType)

    },
    // 添加测点
    async addPointFun() {
      const { pointName, fkNodeId, monitoringType } = this.ruleForm
      const fkTaskId = this.taskId
      console.log(pointName, fkTaskId, fkNodeId, monitoringType)
      const { data: res } = await addPoint(pointName, fkTaskId, fkNodeId, monitoringType)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findAllPointFun()
      this.handleClose()
    },
    handleClose() {
      this.$emit('handleClose')
    },
    // filterNodeList(e) {

    //   if (e === '轴力监测') {




    //     this.nodeList = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
    //       if (item.nodeNumber.includes('V2.5')) {
    //         return item.nodeType === 'F'
    //       } else {
    //         return item.nodeType === 'VF'
    //       }
    //     })

    //   } else if (e === '沉降监测') {


    //     this.nodeList = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
    //       if (item.nodeNumber.includes('V2.5')) {
    //         return item.nodeType === 'V'
    //       } else {
    //         return item.nodeType === 'VF'
    //       }
    //     })


    //   } else {

    //     this.nodeList = JSON.parse(JSON.stringify(this.nodeList)).filter((item) => {
    //       return item.nodeType === 'HL'
    //     })


    //   }

    //   console.log(this.nodeList)
    // }
  },
  created() {
    //
    console.log('123', this.taskId)
    this.findAllNodeByTaskFun()
  },
}
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 300px;
}
</style>
