import service from '../utils/request'

/*  
   查询节点--------------------------------------------------------------------------------------------------------
*/
// 查询所有节点
export function findAllNode(page, pageSize) {
  return service({
    method: 'get',
    url: '/node/findBy',
    params: {
      page,
      pageSize,
    },
  })
}
// 查询单位所有节点
export function findAllNodeByUnit(companyId, page, pageSize) {
  return service({
    method: 'get',
    url: '/node/findBy',
    params: {
      companyId,
      page,
      pageSize,
    },
  })
}

// 查询任务下的节点 v2+v2.5
export function findAllNodeByTaskId(taskId) {
  return service({
    method: 'get',
    url: '/task/findNodeByTask',
    params: {
      taskId,
    },
  })
}

// 主机下 所有节点
export function findAllNodeByHostId(hostId) {
  return service({
    method: 'get',
    url: '/node/findNodeByHost',
    params: {
      hostId,
    },
  })
}

// 根据条件查询节点
export function findNodeList(hostId, taskId, state, page, pageSize) {
  return service({
    method: 'get',
    url: '/node/findBy',
    params: {
      hostId,
      taskId,
      state,
      page,
      pageSize,
    },
  })
}

// 主机下 所有2.5代节点
export function findNode2List(hostId, taskId, state, page, pageSize) {
  return service({
    method: 'get',
    url: '/node2/findBy',
    params: {
      hostId,
      taskId,
      state,
      page,
      pageSize,
    },
  })
}

// 查询单位 任务下所有节点
export function findAllNodeByTask(taskId) {
  return service({
    method: 'get',
    url: '/task/findNodeByTask',
    params: {
      taskId,
    },
  })
}

// 给任务分配节点
export function distributionNodeToTask(taskId, nodeId) {
  return service({
    method: 'put',
    url: '/task/distributionTask',
    params: {
      taskId,
      nodeId,
    },
  })
}
// 移除任务中的节点
export function removeNode(taskId, nodeId) {
  return service({
    method: 'delete',
    url: '/node/removeNode',
    params: {
      taskId,
      nodeId,
    },
  })
}

// 给任务分配节点V2.5
export function distributionNodeToTaskV2(taskId, nodeId) {
  return service({
    method: 'put',
    url: '/node2/distributionNode',
    params: {
      taskId,
      nodeId,
    },
  })
}
// 移除任务中的节点V2.5
export function removeNode2(taskId, nodeId) {
  return service({
    method: 'delete',
    url: '/node2/removeNode',
    params: {
      taskId,
      nodeId,
    },
  })
}

// /api/node/updateState
// 修改节点的状态
export function updateNodeState(itemId, stateId) {
  return service({
    method: 'put',
    url: '/node/updateState',
    params: {
      itemId,
      stateId,
    },
  })
}

export function updateNode2State(itemId, stateId) {
  return service({
    method: 'put',
    url: '/node2/updateState',
    params: {
      itemId,
      stateId,
    },
  })
}

// 2.5代高支模节点远程控制开关机
// /node2/switchNode
export function handleSwitchNode(data) {
  return service({
    method: 'put',
    url: '/node2/switchNode',
    data,
  })
}
