import service from '../utils/request'

// Type F L H V
// 查询 任务中 测点
export function findAllPoint(taskId) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint/findByTaskId',
    params: {
      taskId,
    },
  })
}
// 根据类型 查询 任务中 测点
export function findPointByType(taskId, type) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint/findByType',
    params: {
      taskId,
      type,
    },
  })
}
// 测点详情
export function findPointById(id) {
  return service({
    method: 'get',
    url: '/taskMonitoringPoint/findById',
    params: {
      id,
    },
  })
}

// 添加测点
export function addPoint(pointName, fkTaskId, nodeNumber, monitoringType) {
  return service({
    method: 'POST',
    url: '/taskMonitoringPoint/addTaskMonitoringPoint',
    data: {
      pointName,
      fkTaskId,
      // fkNodeId,
      nodeNumber,
      monitoringType,
    },
  })
}

// 批量添加测点
export function addPointList(taskId, pointName, type, startValue, endValue) {
  return service({
    method: 'POST',
    url: '/taskMonitoringPoint/addPoints',
    data: {
      taskId,
      pointName,
      type,
      startValue,
      endValue,
    },
  })
}

// 删除测点
export function delPoint(id) {
  return service({
    method: 'DELETE',
    url: '/taskMonitoringPoint/removeTaskMonitoringPoint',
    params: {
      id,
    },
  })
}

// 批量删除测点
export function delPointList(ids) {
  return service({
    method: 'DELETE',
    url: '/taskMonitoringPoint/removePoints',
    params: {
      ids,
    },
  })
}
// 修改测点 的 节点
export function updatePointNode(pkItemId, nodeNumber) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint/banding',
    data: {
      pkItemId,
      nodeNumber,
    },
  })
}
// 修改测点信息
export function updatePointInfo(info) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint/updateTaskMonitoringPoint',
    data: info,
  })
}

// PUT /api/taskMonitoringPoint/updateTaskMonitoringPoint
// PUT /api/taskMonitoringPoint/updateRange
// 修改 轴力 量程
export function updateTaskSpan(taskId, range) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint/updateRange',
    params: {
      taskId,
      range,
    },
  })
}

// 测点的批量操作
// 批量设置报警预警值
export function updatePointsAlarm(taskId, type, warning, alarm) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint/updatePoints',
    params: {
      taskId,
      type,
      warning,
      alarm,
    },
  })
}

// 批量设置初始值
export function updatePointsValue(taskId, type) {
  return service({
    method: 'put',
    url: '/taskMonitoringPoint/updateValue',
    params: {
      taskId,
      type,
    },
  })
}

// 数据查询---------------------------------------------------------------------------------------------------

// // 测点数据查询
// export function findPointData(protocol, type, taskId, itemId, page, pageSize, time1, time2) {
//   // V3
//   if (protocol.indexOf('V3') != -1) {
//     return service({
//       method: 'get',
//       url: `data/find${protocol[0]}${type}`,
//       params: {
//         taskId,
//         itemId,
//         page,
//         pageSize,
//         time1,
//         time2,
//         versions: 3,
//       },
//     })
//   } else {
//     return service({
//       method: 'get',
//       url: `data/find${protocol[0]}${type}`,
//       params: {
//         taskId,
//         itemId,
//         page,
//         pageSize,
//         time1,
//         time2,
//       },
//     })
//   }
//   //
// }
// // 测点数据同组查询
// export function findPointDataByGroup(protocol, type, taskId, page, pageSize, time1, time2) {
//   return service({
//     method: 'get',
//     url: `data/find${protocol}${type}`,
//     params: {
//       taskId,
//       page,
//       pageSize,
//       time1,
//       time2,
//     },
//   })
// }

// // 测点数据下载
// export function downloadPointData(taskId, itemId, type) {
//   return service({
//     method: 'get',
//     url: `data/upload`,
//     params: {
//       taskId,
//       itemId,
//       type,
//     },
//   })
// }

// // 测点历史数据查询
// export function findPointHistoryData(protocol, type, taskId, itemId, page, pageSize, startTime, endTime) {
//   return service({
//     method: 'get',
//     url: `historical/find${protocol}${type}Historical`,
//     params: {
//       taskId,
//       itemId,
//       page,
//       pageSize,
//       startTime,
//       endTime,
//     },
//   })
// }

// // 测点历史数据同组查询
// export function findPointHistoryDataByGroup(protocol, type, taskId, page, pageSize, startTime, endTime) {
//   return service({
//     method: 'get',
//     url: `historical/find${protocol}${type}Historical`,
//     params: {
//       taskId,
//       page,
//       pageSize,
//       startTime,
//       endTime,
//     },
//   })
// }

// export function findResultByIds(ids, startTime, endTime) {
//   return service({
//     method: 'get',
//     url: '/data/findResultByIds',
//     params: {
//       ids,
//       startTime,
//       endTime,
//     },
//   })
// }
