import service from '../utils/request'

// 查询任务下的 节点V3
export function findAllNodeByTaskId(taskId) {
  return service({
    method: 'get',
    url: '/node3/findBy',
    params: {
      taskId,
      page: 1,
      pageSize: 1000,
      type: '节点',
    },
  })
}

// 3代高支模节点远程控制开关机

export function handleSwitchNode3(data) {
  return service({
    method: 'put',
    url: '/node3/switchNode',
    data,
  })
}

// 主机下 所有3代节点
export function findNode3List(hostId, taskId, state, page, pageSize, type = '节点') {
  return service({
    method: 'get',
    url: '/node3/findBy',
    params: {
      hostId,
      taskId,
      state,
      page,
      pageSize,
      type,
    },
  })
}

// 主机下 所有3代网关
export function findGatewayList(hostId, taskId, state, type = '网关') {
  return service({
    method: 'get',
    url: '/node3/findGateway',
    params: {
      hostId,
      taskId,
      state,
      type,
    },
  })
}

// 移除任务中的节点V3
export function removeNode3(taskId, nodeId) {
  return service({
    method: 'delete',
    url: '/node3/removeNode',
    params: {
      taskId,
      nodeId,
    },
  })
}

// 给任务分配节点 3代
export function distributionNodeToTaskV3(taskId, gateways) {
  return service({
    method: 'put',
    url: '/node3/allocation',
    params: {
      taskId,
      gateways,
    },
  })
}

// 修改节点的状态
export function updateNodeState(itemId, stateId) {
  return service({
    method: 'put',
    url: '/node3/updateState',
    params: {
      itemId,
      stateId,
    },
  })
}
