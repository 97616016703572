import service from '../utils/request'

export function findSoundByHost(hostId, page, pageSize) {
  return service({
    method: 'get',
    url: `/sound/findByHost`,
    params: {
      hostId,
      page,
      pageSize,
    },
  })
}
export function findSoundByTask(taskId) {
  return service({
    method: 'get',
    url: `/sound/findByTask`,
    params: {
      taskId,
    },
  })
}
export function setSound(taskId, id) {
  return service({
    method: 'put',
    url: `/sound/distribution`,
    params: {
      taskId,
      id,
    },
  })
}
export function delSound(id) {
  return service({
    method: 'put',
    url: `/sound/updateState`,
    params: {
      state: 0,
      id,
    },
  })
}
export function operation(taskId, operation) {
  return service({
    method: 'get',
    url: `/sound/operation`,
    params: {
      operation,
      taskId,
    },
  })
}

// export function operation(deviceName, switchAlarm) {
//   return service({
//     method: 'get',
//     url: `/sound/operation`,
//     params: {
//       deviceName,
//       switchAlarm,
//     },
//   })
// }
