<template>
  <div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
      <el-form-item label="测点前缀" prop="pointName">
        <el-input v-model="ruleForm.pointName"></el-input>
      </el-form-item>

      <el-form-item label="测点类型" prop="monitoringType">
        <el-select v-model="ruleForm.monitoringType" placeholder="请选择测点类型">
          <el-option label="V-沉降监测(v3)" value="沉降监测"></el-option>
          <el-option label="F-轴力监测(v3)" value="轴力监测"></el-option>
          <el-option label="L-倾角监测(v3)" value="倾角监测"></el-option>
          <el-option label="H-水平位移监测(v3)" value="位移监测"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="起始值" prop="startNumber">
        <el-input v-model.number="ruleForm.startNumber"></el-input>
      </el-form-item>

      <el-form-item label="结束值" prop="endNumber">
        <el-input v-model.number="ruleForm.endNumber"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
        <el-button @click="handleClose">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//  查询任务中的节点
import { addPointList } from '@/api/PointV3.js'

export default {
  data() {
    return {
      ruleForm: {
        pointName: null,
        monitoringType: '沉降监测',
        startNumber: null,
        endNumber: null,
      },
      rules: {
        pointName: [{ required: true, message: '请输入测点编号', trigger: 'blur' }],
        monitoringType: [{ required: true, message: '请选择测点类型', trigger: 'blur' }],
        startNumber: [{ required: true, message: '请输入起始值', trigger: 'blur' }],
        endNumber: [{ required: true, message: '请输入结束值', trigger: 'blur' }],
      },
    }
  },
  watch: {},
  props: {
    taskId: {
      type: Number,
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.ruleForm.startNumber >= this.ruleForm.endNumber) {
            this.$message({
              message: '请输入合法数值',
              type: 'error',
            })
            return
          }
          this.addPointFun()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 添加测点
    async addPointFun() {
      const { pointName, monitoringType, startNumber, endNumber } = this.ruleForm
      const fkTaskId = this.taskId

      const { data: res } = await addPointList(fkTaskId, pointName, monitoringType, startNumber, endNumber)

      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.findAllPointFun()
      this.handleClose()
    },
    handleClose() {
      this.$emit('handleClose')
    },
  },
  created() {
    console.log('this.taskId', this.taskId)
  },
}
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 300px;
}
</style>
