<template>
  <div class="TaskJob">
    <el-row>
      <el-button class="mr_20" v-permission="2" type="primary" icon="el-icon-plus" @click="handleClick(null, 'add')">新增任务</el-button>
      <el-button-group class="mr_20">
        <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'message')">工程信息</el-button>
        <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'point')">测点配置</el-button>
        <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'sound')">声光报警器配置</el-button>
        <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'video')">视频配置</el-button>
        <!-- <el-button type="primary" :disabled="disabled" @click="handleClick(null, 'subway')">地铁对接配置</el-button> -->
      </el-button-group>

      <el-select class="host mr_20" v-model="hostId" filterable placeholder="请选择主机查看任务">
        <el-option v-for="item in hostList" :key="item.pkHostId" :label="'当前主机:' + item.hostNumber" :value="item.pkHostId">
          <p>
            {{ item.hostNumber }}
            <span style="color: green; float: right">{{ item.company.companyName }}</span>
          </p>
        </el-option>
      </el-select>

      <el-input v-model="projectName" class="mr_20">
        <template slot="prepend"><span>工程名称</span></template>
        <el-button slot="append" icon="el-icon-search" @click="findAllProject">查询 </el-button>
      </el-input>
    </el-row>
    <MyTable
      ref="myTable"
      :dataSource="dataSource"
      :columns="columns"
      border
      :loading="loading"
      :total="total"
      :currentPage="currentPage"
      :pageSize="pageSize"
      :highlightCurrentRow="true"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      @handleClick="handleClick"
      @handleSelectItem="handleSelectItem"
    ></MyTable>

    <el-dialog class="addTask" title="添加工程" center :visible.sync="dialogVisibleAddTask" :before-close="handleClose">
      <AddTaskDialog v-if="dialogVisibleAddTask"></AddTaskDialog>
    </el-dialog>

    <el-dialog class="TaskInfo" title="工程详情" center :visible.sync="dialogVisibleTaskInfo" :before-close="handleClose">
      <TaskInfoDialog v-if="dialogVisibleTaskInfo" :hostId="hostId" :pkTaskId="pkTaskId"></TaskInfoDialog>
    </el-dialog>

    <el-dialog class="PointConfigDialog" title="测点配置" center :visible.sync="dialogVisiblePointConfig" :before-close="handleClose">
      <PointConfigDialog v-if="dialogVisiblePointConfig" :projectId="pkTaskId"></PointConfigDialog>
    </el-dialog>

    <el-dialog title="上传任务相关文件" class="upload" center :visible.sync="dialogVisibleUp" width="500px" height="500px" :before-close="handleClose">
      <Uploading :pkTaskId="pkTaskId" v-if="dialogVisibleUp"></Uploading>
    </el-dialog>

    <el-dialog title="修改任务状态" class="upState" center :visible.sync="dialogVisibleRunningState" width="500px" height="500px" :before-close="handleClose">
      <UpdateTaskRunState :taskId="pkTaskId" :state="stateNum" v-if="dialogVisibleRunningState"></UpdateTaskRunState>
    </el-dialog>

    <el-dialog class="sound" title="声光报警器配置" center :visible.sync="dialogVisibleSound" :before-close="handleClose">
      <SoundConfig v-if="dialogVisibleSound" :pkTaskId="pkTaskId" :hostId="hostId"></SoundConfig>
    </el-dialog>

    <el-dialog class="video" title="视频列表信息" center :visible.sync="dialogVisibleVideo" :before-close="handleClose">
      <VideoConfig v-if="dialogVisibleVideo" :pkTaskId="pkTaskId" :hostId="hostId"></VideoConfig>
    </el-dialog>
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import PointConfigDialog from './PointDeploy/index.vue'
import AddTaskDialog from './AddTask.vue'
import TaskInfoDialog from './TaskInfo.vue'
import SoundConfig from './SoundConfig.vue'
import VideoConfig from './VideoConfig.vue'

import { findHostByUnit, findAllHost, findHostByUserIdHost, findHostByUserIdUser } from '@/api/Host.js'
import { findTaskByHostId, removeTask } from '@/api/Task.js'
import Uploading from './Uploading.vue'
import UpdateTaskRunState from './UpdateTaskRunState.vue'
const columns = [
  {
    prop: 'projectNo',
    label: '工程编号',
    type: 'text',
    align: 'center',
    sortable: true,
  },
  {
    prop: 'projectName',
    label: '工程名称',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'projectAddress',
    label: '工程地址',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'hostNumber',
    label: '所属主机',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'safeState',
    label: '安全状态',
    width: 150,
    type: 'text',
    align: 'center',
  },
  {
    prop: 'runningState',
    label: '运行状态',
    width: 150,
    type: 'button',
    align: 'center',
    buttonInfo: {
      text: null,
      type: null,
      size: 'mini',
    },
  },

  {
    prop: 'upload',
    label: '上传',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '上传',
      type: 'success',
      size: 'mini',
    },
  },
  {
    prop: 'del',
    label: '删除',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '删除',
      type: 'danger',
      size: 'mini',
    },
  },
  {
    prop: 'info',
    label: '任务详情',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '查看',
      type: '',
      size: 'mini',
    },
  },
  {
    prop: 'view',
    label: '运行情况展示',
    align: 'center',
    type: 'button',
    width: 120,
    buttonInfo: {
      text: '查看',
      type: '',
      size: 'mini',
    },
  },
]
const dataSource = []
export default {
  name: 'Job',
  components: { MyTable, AddTaskDialog, TaskInfoDialog, Uploading, UpdateTaskRunState, PointConfigDialog, SoundConfig, VideoConfig },
  data() {
    return {
      projectName: null,
      currentPage: 0,
      pageSize: 10,
      total: 30,
      loading: true,
      dataSource,
      columns,
      stateNum: null,
      stateId: 0,
      pkTaskId: null,
      hostId: null,
      hostList: [],
      // 弹窗
      dialogVisibleAddTask: false,
      dialogVisibleTaskInfo: false,
      dialogVisibleUp: false,
      dialogVisiblePointConfig: false,
      dialogVisibleRunningState: false,
      dialogVisibleSound: false,
      dialogVisibleVideo: false,

      disabled: true,
    }
  },
  watch: {
    hostId(v) {
      console.log('new', v)
      // console.log(this.$refs.myTable)

      this.$refs.myTable.setCurrent()
      // this.$refs.myTable.setCurrentRow([])

      this.handleSelectItem(null)

      this.findAllProject()
    },
  },

  methods: {
    /*
      根据不同的用户 返回不同的数据
      admin     查询所有主机
      系统管理员 查询单位所有主机
      主机管理员 查询用户拥有的所有主机
      普通用户   查询用户拥有的所有主机
    */
    async findHostByUser() {
      this.loading = true
      if (this.$store.state.power === 4) {
        return await findAllHost(0, 99999)
      }
      if (this.$store.state.power === 3) {
        return await findHostByUnit(this.$store.state.unitId, 0, 99999)
      }
      if (this.$store.state.power === 2) {
        return await findHostByUserIdHost(this.$store.state.userId, 0, 99999)
      }
      if (this.$store.state.power === 1) {
        return await findHostByUserIdUser(this.$store.state.userId)
      }
    },
    async findAllHost() {
      const { data: res } = await this.findHostByUser()

      this.hostList = res.data.list || res.data
      this.hostList.forEach((item) => {
        item.companyName = item.company.companyName
      })
      console.log('this.$route==>', this.$route.params.hostId)
      this.hostId = this.$route.params.hostId ? this.$route.params.hostId : this.hostList.length > 0 ? this.hostList[this.hostList.length - 1].pkHostId : null
    },
    async findAllProject() {
      this.loading = true

      if (!this.hostId) {
        //如果不存在主机ID时，不需要调用API查询主机下任务
        setTimeout(() => {
          this.loading = false
        }, 300)
        return
      }

      this.dataSource = []
      const { data: res } = await findTaskByHostId(this.projectName, this.hostId, this.currentPage, this.pageSize)
      console.log('任务数据？？？？？？', res.data.list)
      this.dataSource = res.data.list
      this.dataSource.forEach((item) => {
        item.hostNumber = item.host.hostNumber
        item.companyName = item.company.companyName

        switch (item.fkTaskRunningStateId) {
          case 1:
            item.runningState = '登记'
            break
          case 2:
            item.runningState = '运行中'
            break
          case 3:
            item.runningState = '完工'
            break
          case 4:
            item.runningState = '迁移中'
            break
        }

        item.safeState = item.safeState.safeState
      })
      this.total = res.data.total
      setTimeout(() => {
        this.loading = false
      }, 300)
    },

    async removeTaskFun(id) {
      const { data: res } = await removeTask(id)
      this.$message({
        message: '任务' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findAllProject()
    },

    init() {
      this.findAllHost().then(() => {
        this.findAllProject()
      })
    },
    handleClick(row, type) {
      console.log(row, type)
      switch (type) {
        case 'del':
          console.log('del')
          this.removeTaskFun(row.pkTaskId)
          break
        case 'add':
          this.dialogVisibleAddTask = true
          break
        case 'message':
          this.dialogVisibleTaskInfo = true
          break
        case 'info':
          window.open('/ProjectMonitor/' + (row.pkTaskId - 0) + '/Condition')
          break
        case 'view':
          window.open('/StatisticalGraph/' + (row.pkTaskId - 0))
          break
        case 'upload':
          this.dialogVisibleUp = true
          break
        case 'point':
          this.dialogVisiblePointConfig = true
          break
        case 'sound':
          this.dialogVisibleSound = true
          break
        case 'video':
          this.dialogVisibleVideo = true
          break
        case 'runningState':
          this.stateNum = row.state.pkTaskRunningStateId
          this.dialogVisibleRunningState = true
          break
      }
    },

    handleClose() {
      this.dialogVisibleVideo = this.dialogVisibleSound = this.dialogVisibleAddTask = this.dialogVisibleTaskInfo = this.dialogVisibleUp = this.dialogVisiblePointConfig = this.dialogVisibleRunningState = false
    },
    handleSizeChange(val) {
      this.pageSize = val
      console.log('this.pageSize', this.pageSize)
      this.findAllProject()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      console.log('this.currentPage', this.currentPage)
      this.findAllProject()
    },
    handleSelectItem(e) {
      console.log('选择了', e)
      // 未选择时 禁止使用按钮 并且ID为null
      if (!e) {
        this.pkTaskId = null
        this.disabled = true
        return
      }

      this.disabled = false
      // 当前工程ID
      this.pkTaskId = e.pkTaskId
    },
    // setCurrent(){
    //   setCurrent
    // }
  },
  created() {
    this.init()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.TaskJob {
  padding: 10px;
  width: calc(100% - 20px);

  .el-row {
    margin-top: 10px;
    .el-input,
    .el-select {
      margin-bottom: 10px;
    }
  }

  .mr_20 {
    margin-right: 20px;
  }

  .el-input {
    width: 500px;
  }

  .el-select {
    width: 300px;

    ::v-deep .el-input__inner {
      color: #f56c6c !important;
    }
  }
}

.addTask ::v-deep .el-dialog {
  width: 800px;
  height: 600px;
}

.upload ::v-deep .el-dialog {
  width: 500px;
  height: 800px;
}

.upState ::v-deep .el-dialog {
  width: 350px !important;
  height: 240px;
}

.TaskInfo ::v-deep .el-dialog {
  width: 1360px;
  height: 1000px;

  .el-dialog__body {
    padding: 0 25px;
  }
}

.TaskJob {
  ::v-deep .el-message-box__content {
    height: 200px !important;
  }
}

.sound ::v-deep .el-dialog {
  width: 1200px;
  height: 800px;
}

.subway ::v-deep .el-dialog {
  width: 600px;
  height: 500px;
}

.el-message-box__message {
  height: 200px;
}
</style>
