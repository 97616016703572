<!-- 测点配置内容模块 -->
<template>
  <div>
    <!-- V2 以及 V2.5 测点配置-->
    <PointDeployV2 :projectId="projectId" />
    <!-- V3 测点配置  -->
    <PointDeployV3 :projectId="projectId" />
  </div>
</template>

<script>
import PointDeployV2 from './V2_V2.5/PointDeploy'
import PointDeployV3 from './V3/PointDeploy'
export default {
  data() {
    return {}
  },
  props: {
    projectId: {
      type: Number,
    },
  },
  components: { PointDeployV2, PointDeployV3 },
  methods: {},
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
