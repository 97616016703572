<template>
  <div class="uploadDialog">
    <el-form label-position="top" label-width="100%">
      <el-form-item label="请选择文件种类">
        <el-select v-model="form.fileForm" placeholder="请选择上传文件类型" @change="changeFileForm">
          <el-option v-for="item in list" :key="item.typeId" :label="item.name" :value="item.typeId"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传文件" class="up">
        <el-upload class="upload-demo" :accept="accept" :file-list="fileList" :auto-upload="false" action="#" :limit="1" :on-change="getValChange" :on-exceed="handleExceed" list-type="picture" multiple drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip" v-if="!isPic">tips: 只能上传Pdf文件，且不超过40MB</div>
          <div class="el-upload__tip" slot="tip" v-else>tips: 只能上传jpg,png文件，且不超过10MB</div>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="uploadFileFun">立即上传</el-button>
        <el-button @click="$parent.handleClose()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { uploadFile } from '@/api/Uploading.js'
export default {
  data() {
    return {
      isPic: false,
      accept: '.pdf',
      form: {
        fileForm: 3,
      },
      fileList: [],
      file: {},
      list: [
        {
          name: '展示图1',
          fileType: '.jpg,.png',
          type: 'flatPatternmakingOne',
          typeId: 1,
          isPic: true,
        },
        {
          name: '展示图2',
          fileType: '.jpg,.png',
          type: 'flatPatternmakingTwo',
          typeId: 2,
          isPic: true,
        },
        {
          name: '简报',
          fileType: '.pdf',
          type: 'briefReport',
          typeId: 3,
          isPic: false,
        },
        {
          name: '总报',
          fileType: '.pdf',
          type: 'alwaysQuote',
          typeId: 4,
          isPic: false,
        },
        {
          name: '平面图',
          fileType: '.jpg,.png',
          type: 'plan',
          typeId: 5,
          isPic: true,
        },
        {
          name: '监测方案',
          fileType: '.pdf',
          type: 'monitoringProgramme',
          typeId: 6,
          isPic: false,
        },
        {
          name: '审批报告',
          fileType: '.pdf',
          type: 'examinationApprovalReport',
          typeId: 7,
          isPic: false,
        },
        {
          name: '监测实施方案',
          fileType: '.pdf',
          type: 'implementationPlan',
          typeId: 8,
          isPic: false,
        },
      ],
    }
  },
  props: {
    pkTaskId: {
      type: Number,
    },
  },
  methods: {
    async uploadFileFun() {
      if (this.fileList[0].length === 0) {
        this.$message({
          message: '请上传文件',
          type: 'error',
        })
        return
      }

      const task = {
        pkTaskId: this.pkTaskId,
        [this.list[this.form.fileForm - 1].type]: 'true',
      }

      let formData = new FormData()
      formData.append('file', this.fileList[0])
      // formData.append('pkTaskId', this.pkTaskId)
      // formData.append([this.list[this.form.fileForm - 1].type], 'true')
      console.log(task, formData)
      const { data: res } = await uploadFile(this.pkTaskId, this.form.fileForm, formData)


      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.$parent.handleClose()
    },
    //   文件状态改变
    getValChange(file, fileList) {
      this.fileList = []
      if (fileList.length === 1) {
        this.fileList = [file.raw]
      }
    },
    // 超出文件数量限制
    handleExceed(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = []
        this.fileList = [file[0]]
      }
    },
    changeFileForm(e) {
      this.file = null
      this.fileList = []
      this.accept = this.list[e - 1].fileType
      this.isPic = this.list[e - 1].isPic
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped lang="scss">
.uploadDialog {
  ::v-deep .el-upload-dragger {
    width: 450px;
  }
  ::v-deep .el-upload-list.el-upload-list--picture {
    width: 450px;
  }
  .el-select {
    width: 450px;
  }
}
</style>
