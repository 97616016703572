<template>
  <div class="TaskInfo">
    <el-steps :active="active" finish-status="success" align-center>
      <el-step title="基本信息"></el-step>
      <el-step title="任务相关"></el-step>
      <el-step title="任务参数"></el-step>
    </el-steps>

    <el-carousel ref="cardShow" indicator-position="none" arrow="never" :autoplay="false" style="margin-top: 20px">
      <el-carousel-item ref="acItem">
        <el-form ref="formPrimary" :model="primary" :rules="primaryRules" label-width="110px">
          <el-form-item label="任务名称" prop="projectName">
            <el-input v-model="primary.projectName" maxlength="35" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="任务编号" prop="projectNo">
            <el-input v-model="primary.projectNo"></el-input>
          </el-form-item>
          <el-form-item label="主机编号" prop="fkHostId">
            <el-select class="host" v-model="primary.fkHostId" filterable placeholder="请选择主机">
              <el-option v-for="item in hostList" :key="item.pkHostId" :label="item.hostNumber" :value="item.pkHostId"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="监测编号" prop="supervisionPlatformNo">
            <el-input v-model="primary.supervisionPlatformNo" placeholder="监测编号 对接监管平台"></el-input>
          </el-form-item>

          <el-form-item label="GPS坐标">
            <el-input class="GPS" v-model.number="primary.longitude" placeholder="请输入 经度"></el-input>
            <el-input class="GPS" v-model.number="primary.latitude" placeholder="请输入  纬度"></el-input>
            <el-button class="GPS" @click="showMap">地图定位</el-button>
          </el-form-item>

          <el-form-item label="工程地址" prop="projectAddress">
            <el-input v-model="primary.projectAddress"></el-input>
          </el-form-item>
        </el-form>
      </el-carousel-item>

      <el-carousel-item>
        <el-form ref="form" label-width="80px">
          <el-row v-for="(item, index) in contacts" :key="index">
            <el-col :span="10">
              <el-form-item label="联 系 人 " class="personPhone" :rules="index < 2 ? { required: true, message: '前两个联系人必填', trigger: 'blur' } : {}">
                <el-input class="person" v-model="item.name" :placeholder="'负责人 ' + (index - 0 + 1)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14">
              <el-form-item label="联系电话" class="personPhone" :rules="index < 2 ? { required: true, message: '前两个联系人必填', trigger: 'blur' } : {}">
                <el-input class="phone" v-model="item.phone" :placeholder="'负责人 ' + (index - 0 + 1) + ' 电话'"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-col :span="24">
            <el-form-item label="Tips:" class="Tips personPhone">
              <p style="color: #f47378; font-size: 14px">1.如需添加联系人,请确保手机号与联系人一一对应,无联系人或者无手机号时，该联系项无效！</p>
              <p style="color: #f47378; font-size: 14px">2.如需要填写超过4个以上报警联系人，请前往工程信息页面继续添加</p>
              <p style="color: #f47378; font-size: 14px">3.前两个联系人第一联系人！！！必填项！！！</p>
            </el-form-item>
          </el-col>
        </el-form>
      </el-carousel-item>

      <el-carousel-item>
        <el-form ref="form" :model="parameter" label-width="110px">
          <el-col :span="12">
            <el-form-item label="建筑面积">
              <el-input v-model="parameter.coveredArea">
                <template slot="append">m²</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模板线荷载">
              <el-input v-model="parameter.formworkLineLoad"><template slot="append">kN/m</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模板面荷载">
              <el-input v-model="parameter.formworkSurfaceLoad"><template slot="append">kN/m²</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模板设计沉量">
              <el-input v-model="parameter.templateDesignWeight"> <template slot="append">mm</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模板跨度">
              <el-input v-model="parameter.templateSpan"><template slot="append">m</template></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="模板高度">
              <el-input v-model="parameter.templateHeight"><template slot="append">m</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="立杆设计承载力">
              <el-input v-model="parameter.designBearingCapacityOfPole"><template slot="append">km</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="支模形式">
              <el-input v-model="parameter.modularForm"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24"> <p style="color: #f47378; font-size: 16px; text-align: center">温馨提示:请您认真核对标注 * 号的内容</p> </el-col>
        </el-form>
      </el-carousel-item>
    </el-carousel>

    <el-row class="center">
      <el-button style="margin-top: 12px" @click="prev" :disabled="active === 0">上一步</el-button>
      <el-button style="margin-top: 12px" @click="next" :disabled="false" v-show="active < 2">下一步</el-button>
      <el-button style="margin-top: 12px" @click="addTaskFun" :disabled="false" v-show="active === 2">立即创建</el-button>
    </el-row>

    <el-dialog class="map-container" center title="双击选择后点击任意空白即可" :visible="mapVisible" :before-close="handleMapClose" :modal-append-to-body="true" :append-to-body="true" :fullscreen="true">
      <TianDiMap style="width: 100%; height: 100%" class="map" :addMarkerFlag="true" :lng="this.primary.longitude" :lat="this.primary.latitude" @addMarkerSuccess="changeLngLat"> </TianDiMap>
    </el-dialog>
  </div>
</template>

<script>
import { findHostByUnit, findAllHost, findHostByUserIdHost, findHostByUserIdUser } from '@/api/Host.js'
import { addTask, addPhone } from '@/api/Task.js'

const TianDiMap = () => ({
  component: import('@/components/TianDiMap'),
})
export default {
  components: { TianDiMap },
  watch: {
    'primary.longitude'(v) {
      v === '' ? (this.primary.longitude = null) : (this.primary.longitude = v)
    },
    'primary.latitude'(v) {
      v === '' ? (this.primary.latitude = null) : (this.primary.latitude = v)
    },
  },
  data() {
    return {
      active: 0,
      currentPage: 1,
      pageSize: 9999,
      mapVisible: false,

      // 主要信息
      primary: {
        fkHostId: null,
        projectNo: null,
        projectName: null,
        projectAddress: null,
        supervisionPlatformNo: null,

        longitude: null,
        latitude: null,
      },
      primaryRules: {
        fkHostId: [
          {
            required: true,
            message: '请选择主机',
            trigger: 'blur',
          },
        ],
        projectNo: [
          {
            required: true,
            message: '请输入工程编号',
            trigger: 'blur',
          },
        ],
        projectName: [
          {
            required: true,
            message: '请输入工程名称',
            trigger: 'blur',
          },
          { max: 35, message: '工程名称不能超过35个字符' },
        ],
        // projectAddress: [{
        //   required:true,message:'请输入工程地址',trigger:'blur'
        // }],
        // supervisionPlatformNo: [{
        //   required:true,message:'请输入监测平台编号名称',trigger:'blur'
        // }],
        lonAndLat: [
          {
            required: true,
            message: '请输入工程定位',
            trigger: 'blur',
          },
        ],
      },
      // 联系信息
      contacts: [
        {
          name: null,
          phone: null,
        },
        {
          name: null,
          phone: null,
        },
        {
          name: null,
          phone: null,
        },
        {
          name: null,
          phone: null,
        },
        // {
        //   name: null,
        //   phone: null,
        // },
      ],

      // 工程参数
      parameter: {
        // 建筑面积
        coveredArea: null,
        formworkLineLoad: null,
        formworkSurfaceLoad: null,
        templateDesignWeight: null,
        templateSpan: null,
        templateHeight: null,
        designBearingCapacityOfPole: null,
        modularForm: null,
      },
      isDisabled: true,
      hostList: [],
    }
  },
  methods: {
    /* 
      根据不同的用户 返回不同的数据
      admin     查询所有主机
      系统管理员 查询单位所有主机
      主机管理员 查询用户拥有的所有主机
      普通用户   查询用户拥有的所有主机
    */
    async findHostByUser() {
      this.loading = true
      if (this.$store.state.power === 4) {
        return await findAllHost(this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 3) {
        return await findHostByUnit(this.$store.state.unitId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 2) {
        return await findHostByUserIdHost(this.$store.state.userId, this.currentPage, this.pageSize)
      }
      if (this.$store.state.power === 1) {
        return await findHostByUserIdUser(this.$store.state.userId, this.currentPage, this.pageSize)
      }
    },
    async findAllHost() {
      const { data: res } = await this.findHostByUser()
      this.hostList = res.data.list
      this.hostList.forEach((item) => {
        item.companyName = item.company.companyName
      })
      this.hostId = this.hostList[0].pkHostId
    },
    async addPhoneFun(TaskId) {
      const phoneList = []
      this.contacts.forEach((item) => {
        if (item.name && item.phone) {
          const contact = {
            name: item.name,
            phone: item.phone,
            fkTaskId: TaskId,
          }
          phoneList.push(contact)
        }
      })

      const { data: res } = await addPhone(phoneList)

      this.$message({
        message: '联系人' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      // this.$emit('handleClose')
      this.$parent.$parent.findAllProject()
      this.$parent.$parent.handleClose()
      // this.handleClose()
    },
    async addTaskFun() {
      //提示未填信息
      if (!this.primary.projectName || !this.primary.projectNo || !this.primary.fkHostId) {
        this.$message({
          message: '请检查主要信息',
          type: 'warning',
        })
        return
      }

      if (!this.contacts[0].name || !this.contacts[1].name || !this.contacts[0].phone || !this.contacts[1].phone) {
        this.$message({
          message: '请检查联系人',
          type: 'warning',
        })
        return
      }

      const taskInfo = {
        //
        fkHostId: this.primary.fkHostId,
        projectNo: this.primary.projectNo,
        projectName: this.primary.projectName,
        projectAddress: this.primary.projectAddress,
        supervisionPlatformNo: this.primary.supervisionPlatformNo,
        longitude: this.primary.longitude,
        latitude: this.primary.latitude,
        //
        coveredArea: this.parameter.coveredArea,
        formworkLineLoad: this.parameter.formworkLineLoad,
        formworkSurfaceLoad: this.parameter.formworkSurfaceLoad,
        templateDesignWeight: this.parameter.templateDesignWeight,
        templateSpan: this.parameter.templateSpan,
        templateHeight: this.parameter.templateHeight,
        designBearingCapacityOfPole: this.parameter.designBearingCapacityOfPole,
        modularForm: this.parameter.modularForm,
      }

      const { data: res } = await addTask(taskInfo)

      console.log('添加任务的返回', res.data)

      this.$message({
        message: '任务' + res.message,
        type: res.code === 200 ? 'success' : 'error',
      })

      this.addPhoneFun(res.data)
    },

    changeLngLat(lng, lat) {
      this.primary.longitude = lng - 0
      this.primary.latitude = lat - 0
      // this.primary.lonAndLat = this.primary.longitude + ' , ' + this.primary.latitude
    },
    showMap() {
      this.mapVisible = true
    },
    handleMapClose() {
      this.mapVisible = false
    },
    prev() {
      if (this.active === 0) {
        this.active = 0
      } else {
        this.active--
        this.$refs.cardShow.prev()
      }
    },
    next() {
      if (this.active === 2) {
        this.active = 2
      } else {
        this.active++

        this.$refs.cardShow.next()
      }
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, this.showError)
      } else {
        console.log('该浏览器不支持获取地理位置。')
      }
    },
    showPosition(position) {
      this.primary.longitude = position.coords.longitude - 0
      this.primary.latitude = position.coords.latitude - 0
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log('用户拒绝对获取地理位置的请求。')
          break
        case error.POSITION_UNAVAILABLE:
          console.log('位置信息是不可用的。')
          break
        case error.TIMEOUT:
          console.log('请求用户地理位置超时。')
          break
        case error.UNKNOWN_ERROR:
          console.log('未知错误。')
          break
      }
    },
  },
  created() {
    // 获取定位信息
    this.getLocation()

    this.findAllHost()
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
.el-input,
.el-select {
  width: 100%;
}
.GPS.el-input {
  width: 35%;
}
.GPS.el-button {
  width: 30%;
}
.center {
  text-align: center;
}

// .TaskInfo ::v-deep .el-carousel {
//   // height: 600px;
// }
.TaskInfo ::v-deep .el-carousel__container {
  height: 350px;
  // max-height: 500px;
}

// .Tips {
//   line-height: 20px !important;
//   // color: #ff7f27;
// }
.personPhone {
  p {
    line-height: 30px;
  }
}
</style>
