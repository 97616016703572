<template>
  <div class="monitor-style">
    <el-table :data="tableData" style="width: 100%" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="47"> </el-table-column>
      <el-table-column prop="pointName" label="测点编号" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) || '--' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="nodeNumber" label="节点编号" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="openDtuDialog(scope.row)">{{ nullToDash(scope.row[scope.column.property]) || '--' }}</el-button>
        </template>
      </el-table-column>
      <!--fixme 将这个和下面的dialog封装成一个组件 -->
      <!-- 修改 测点下 DTU -->
      <el-dialog title="修改节点" width="420px" :visible.sync="dtuVisible" :before-close="handleCloseChangeDtu" :modal-append-to-body="true" :append-to-body="true" custom-class="sinDialog">
        <div style="line-height: 24px; margin: 0">请选择节点</div>
        <el-select style="padding-top: 15px; width: 100%" v-model="fkNodeId">
          <el-option v-for="item in rDtuList" :label="item.nodeNumber" :value="item.nodeNumber" :key="item.pkNodeId"> </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button class="el-button el-button--default el-button--small" @click="handleCloseChangeDtu">取消</el-button>
          <el-button class="el-button el-button--default el-button--small el-button--primary" @click="handleChangeDtu">确定</el-button>
        </span>
      </el-dialog>
      <el-table-column prop="createTime" label="创建日期" align="center" width="150">
        <template slot-scope="scope">
          {{ nullToDash(scope.row[scope.column.property]) | time }}
        </template>
      </el-table-column>
      <!-- 111 -->
      <el-table-column prop="runState" label="运行状态" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="openRunStateDialog(scope.row)">{{ nullToDash(scope.row[scope.column.property]) || '--' }}</el-button>
          <!-- <span></span> -->
        </template>
      </el-table-column>
      <!-- 运行状态 对话框 -->
      <el-dialog title="修改运行状态" width="420px" :visible.sync="runStateVisible" :before-close="handleCloseChangeRunState" :modal-append-to-body="true" :append-to-body="true" custom-class="sinDialog">
        <div style="line-height: 24px; margin: 0">请选择运行状态</div>
        <el-select style="padding-top: 15px; width: 100%" v-model="fkRunningStateId">
          <el-option v-for="item of runStateList" :label="item.state" :value="item.value" :key="item.value"> </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
          <el-button class="el-button el-button--default el-button--small" @click="handleCloseChangeRunState">取消</el-button>
          <el-button class="el-button el-button--default el-button--small el-button--primary" @click="handleChangeRunState">确定</el-button>
        </span>
      </el-dialog>

      <el-table-column label="初始值(°)" align="center">
        <el-table-column prop="startingValueOne" label="x角度" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="open(scope.column, scope.row)">{{ nullToDash(scope.row[scope.column.property]) }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="startingValueTwo" label="y角度" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="open(scope.column, scope.row)">
              {{ scope.row.startingValueTwo !== null ? scope.row.startingValueTwo : '--' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="预警角度" align="center" prop="warningValue">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ '填写角度' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="报警角度" align="center" prop="alarmValue">
        <template slot-scope="scope">
          <el-button type="text" @click="open(scope.column, scope.row)">{{ '填写角度' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="预警值(倾角)" align="center" prop="warningValue">
        <template slot="header" slot-scope="scope">
          {{ scope.column.label }}
          <el-tooltip class="item" effect="dark" :content="'tan(预警角度/180 * π)*1000'" placement="top">
            <span><i class="el-icon-question"></i></span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row[scope.column.property] }}
        </template>
      </el-table-column>
      <el-table-column label="报警值(倾角)" align="center" prop="alarmValue">
        <template slot="header" slot-scope="scope">
          {{ scope.column.label }}
          <el-tooltip class="item" effect="dark" :content="'tan(报警角度/180 * π)*1000'" placement="top">
            <span><i class="el-icon-question"></i></span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          {{ scope.row[scope.column.property] }}
        </template>
      </el-table-column>
      <el-table-column label="安全状态" align="center" prop="safeState">
        <template slot-scope="scope">
          <span class="" v-if="scope.row[scope.column.property] === '预警'" style="font-size: 14px; color: #ffa700">{{ scope.row[scope.column.property] }}</span>
          <span class="" v-else-if="scope.row[scope.column.property] === '报警'" style="font-size: 14px; color: #fc011a">{{ scope.row[scope.column.property] }}</span>
          <span class="" v-else style="font-size: 14px; color: #0dbc79">{{ '安全' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="operation" label="操作" align="center" width="100">
        <template slot-scope="scope">
          <i class="el-icon-delete-solid" @click="handleDeletePoint(scope.row)" title="删除测点" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import pointsMixin from './Mixins/points'
export default {
  mixins: [pointsMixin],
  data() {
    return {}
  },
  methods: {
    openL(a, b) {
      console.log(a, b)
    },
  },
  created() {
    console.log(this.tableData)
  },

  mounted() {},
}
</script>

<style scoped lang="scss">
@import '~@/assets/css/monitor-style.scss';
</style>
./V2_V2.5/Mixins/points
