<template>
  <div>
    <div class="title">
      当前任务设备信息:
      <span style="font-weight: 500;color:#D94444">{{ videoNumList.join(',') }}</span>
    </div>
    <h2 class="title">设备列表</h2>
    <MyTable ref="myTable" :dataSource="dataSource" :columns="columns" border :loading="loading" :total="total"
      :currentPage="currentPage" :pageSize="pageSize" :highlightCurrentRow="true" @handleClick="handleClick"
      @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange">
    </MyTable>

    <el-dialog :title="videoNum" :visible.sync="innerVisible" append-to-body>
      <Video :videoId="videoId" :url="url" :videoType="videoType" v-if="innerVisible" />
    </el-dialog>

  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import Video from '@/components/Video'
import { findVideoByHost, findVideoByTask, distributionTask } from '@/api/Video'
// import { videoTypeList } from '@/utils/commonArray.js'
const columns = [
  {
    prop: 'createTime',
    label: '创建日期',
    align: 'center',
    type: 'text',
    sortable: true,
  },
  {
    prop: 'videoNum',
    label: '设备编号',
    type: 'text',
    align: 'center',
  },

  // {
  //   prop: 'videoType',
  //   label: '类型',
  //   type: 'toText',
  //   list: videoTypeList,
  //   align: 'center',
  // },

  {
    prop: 'sim',
    label: 'sim卡',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'url',
    label: '调流URL',
    type: 'text',
    align: 'center',
  },
  {
    prop: 'state',
    label: '可用状态',
    type: 'text',
    align: 'center',
  },

  {
    prop: 'view',
    label: '预览',
    align: 'center',
    type: 'button',
    width: 80,
    buttonInfo: {
      text: '预览',
      type: 'success',
      size: 'mini',
    },
  },
  {
    prop: 'set',
    label: '启用',
    align: 'center',
    type: 'button',
    width: 80,
    buttonInfo: {
      text: '启用',
      type: 'warning',
      size: 'mini',
    },
  },

]
export default {
  props: {
    pkTaskId: { type: Number },
    hostId: { type: Number },
  },
  components: { MyTable, Video },
  data() {
    return {
      currentPage: 0,
      pageSize: 10,
      total: 0,
      loading: true,
      dataSource: [],
      columns,
      videoNumList: [],
      isDisabled: true,
      innerVisible: false,
      videoId: null,
      url: null,
      videoType: null,
      videoNum: null
    }
  },
  methods: {
    async findVideoByHostFun() {
      this.loading = true
      const { data: res } = await findVideoByHost(this.hostId, this.currentPage, this.pageSize)


      this.dataSource = res.data.list
      this.total = res.data.total
      this.loading = false
    },
    async findVideoByTaskFun() {
      const { data: res } = await findVideoByTask(this.pkTaskId)


      const list = res.data.list.map(item => item.videoNum)
      this.videoNumList = list
    },
    // 分配视频到任务
    async distributionTaskFun(id) {
      const { data: res } = await distributionTask(id, this.pkTaskId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.findVideoByHostFun()
      this.findVideoByTaskFun()
    },

    handleSizeChange(val) {
      this.pageSize = val
      this.findVideoByHostFun()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findVideoByHostFun()
    },
    handleClick(row, type) {
      if (type === 'set') {
        this.distributionTaskFun(row.pkVideoId)
      } else if (type === 'view') {
        console.log(row)
        this.videoNum = row.videoNum
        this.videoId = row.pkVideoId
        this.url = row.url
        this.videoType = row.videoType
        this.innerVisible = true
      }
    },
    init() {
      this.findVideoByHostFun()
      this.findVideoByTaskFun()
    },
  },
  created() {
    this.init()
  },
  mounted() { },
}
</script>

<style scoped lang="scss">
.el-descriptions-item__label {
  line-height: 32px;
}

::v-deep .el-descriptions__header {
  margin-bottom: 10px;
}

::v-deep .el-descriptions-item__container {
  line-height: 32px;
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.title {
  color: #303133;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
