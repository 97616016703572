import { delPoint, updatePointNode, updatePointInfo } from '@/api/PointV3.js'
import { updateNodeState } from '@/api/NodeV3.js'

export default {
  props: {
    tableData: {
      type: Array,
    },
    groupNumber: {
      type: Number,
    },
    item: {
      type: String,
    },
    projectId: {
      type: Number,
    },
    runStateList: {
      type: Array,
      default: () => {
        return []
      },
    },
    componentName: {
      type: String,
    },
    dtuList: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      dtuVisible: false,
      runStateVisible: false,
      monitorVisible: false,
      fkNodeId: null,
      fkRunningStateId: null,
      chooseRow: '',
      itemDetail: {},
      alarmVisible: false,
      loading: false,

      rDtuList: [],
    }
  },
  created() {
    console.log('protocol', this.protocol)
    console.log('节点列表', this.dtuList)

    this.handleComponentNameChange()
  },

  methods: {
    /**
     * 打开弹窗 FIXME 传入reg inputFormat outputFormat 有时会显示数据不正确?
     * @param column 列
     * @param row 行
     * @param message 显示的信息
     * @param reg 输入的正则判断
     * @param inputFormat:function 输入时的状态转换
     * @param outputFormat:function 上传时的状态转换
     */

    // 对于不同组节点的处理
    handleComponentNameChange() {
      console.log('Name' + this.componentName)

      if (this.componentName === 'HighMoldLaserVItem') {
        this.rDtuList = JSON.parse(JSON.stringify(this.dtuList)).filter((item) => {
          return item.nodeType === 'V'
        })
      } else if (this.componentName === 'HighMoldAxialFItem') {
        this.rDtuList = JSON.parse(JSON.stringify(this.dtuList)).filter((item) => {
          return item.nodeType === 'F'
        })
      } else {
        this.rDtuList = JSON.parse(JSON.stringify(this.dtuList)).filter((item) => {
          return item.nodeType === 'HL'
        })
      }
    },

    open(column, row, message, reg, inputFormat, outputFormat) {
      console.log(123, column)

      outputFormat ? console.log(outputFormat) : null
      const key = column.property
      const name = column.label
      let oldValue = row[key]
      if (!message) {
        column.property === 'span' ? (message = `请输入5或10的${name}`) : (message = `请输入${name}`)
      }
      if (inputFormat && typeof inputFormat === 'function') {
        oldValue = inputFormat(oldValue)
      }

      if (row.monitoringType === '倾角监测' && (column.property === 'warningValue' || column.property === 'alarmValue')) {
        oldValue = null
      }

      this.$prompt(message, name, {
        inputValue: oldValue,
        inputPattern: reg ? new RegExp(reg) : undefined,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          console.log(value)

          value = column.property === 'pointName' ? value : value - 0

          if (row.monitoringType === '倾角监测' && (column.property === 'warningValue' || column.property === 'alarmValue')) {
            value = Math.tan((value * Math.PI) / 180) * 1000

            value = Math.floor(value * 100) / 100
          }

          console.log(value)
          // 其他值直接提交 倾角监测 填写角度 实际提交倾角
          const info = {
            pkItemId: row.pkItemId,
            [column.property]: value,
          }
          // 去除无效 报警值
          if (column.property === 'alarmValue') {
            if (row.warningValue >= value) {
              this.$message({
                type: 'error',
                message: '无效报警值，报警值必须大于预警值！',
              })
              return
            }
          }
          this.loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          })

          // 调用后台接口
          this.updatePointInfoFun(info)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改',
          })
        })
    },

    openDtuDialog(row) {
      console.log('当前行信息', row)
      this.chooseRow = row
      this.fkNodeId = row.fkNodeId
      this.dtuVisible = true
    },
    openRunStateDialog(row) {
      console.log('row', row)
      this.chooseRow = row
      this.fkRunningStateId = row.state
      this.runStateVisible = true
    },

    handleDeletePoint(row) {
      console.log(row)
      this.$confirm('请认真核对后删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.deletePointFun(row)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleUpdateItem() {},
    handleCloseChangeDtu() {
      this.transmitterId = ''
      this.dtuVisible = false
    },

    handleCloseChangeRunState() {
      this.fkRunningStateId = null
      this.runStateVisible = false
    },

    handleShowAlarm(row) {
      this.itemDetail = row
      this.alarmVisible = true
    },
    handleCloseAlarm() {
      this.alarmVisible = false
    },
    handleShowMonitor(row) {
      this.itemDetail = row
      this.monitorVisible = true
    },
    handleCloseMonitor() {
      this.monitorVisible = false
    },
    handleSelectionChange(e) {
      console.log(e)
      this.$parent.$parent.$parent.$parent.handleSelectionChange(e)
    },
    // 填充内容
    nullToDash(value) {
      return value === null ? '--' : value
    },
    // 删除测点
    async deletePointFun(row) {
      const { data: res } = await delPoint(row.pkItemId)
      console.log(res)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.$parent.$parent.findAllPointFun()
    },

    // 修改 测点的 节点
    async handleChangeDtu() {
      console.log('测点ID:', this.chooseRow.pkItemId, '节点ID', this.fkNodeId)
      const { data: res } = await updatePointNode(this.chooseRow.pkItemId, this.fkNodeId)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.dtuVisible = false
      this.$parent.$parent.$parent.$parent.findAllPointFun()

      this.handleComponentNameChange()
    },

    // 修改 测点的 测点名 初始值 预警值 报警值 特征长度
    async updatePointInfoFun(info) {
      const { data: res } = await updatePointInfo({ ...info })
      console.log(res.code)
      this.$message({
        message: res.message,
        type: res.code === 200 ? 'success' : 'error',
      })
      this.$parent.$parent.$parent.$parent.findAllPointFun()

      this.loading.close()
    },
    // 修改 测点中 节点的 运行状态
    async handleChangeRunState() {
      // console.log(this.chooseRow)
      // console.log(this.fkRunningStateId)
      // let fkRunningStateId = this.fkRunningStateId
      // 无测点情况
      if (!this.chooseRow.nodeNumber) {
        this.$message({
          message: '当前测点无节点',
          type: 'error',
        })
        this.handleCloseChangeRunState()
        return
      }
      // 未修改时 不需要重复提交请求到后台
      if (this.fkRunningStateId === this.chooseRow.state) {
        this.$message({
          message: '当前节点已在该状态',
          type: 'info',
        })
        this.handleCloseChangeRunState()
        return
      } else {
        // console.log('this.chooseRow', this.chooseRow.nodeNumber)
        // console.log('123', this.chooseRow)
        // console.log('321', this.fkRunningStateId)
        // console.log('456', fkRunningStateId)
        const { data: res } = await updateNodeState(this.chooseRow.pkItemId, this.fkRunningStateId)

        this.$message({
          message: res.message,
          type: res.code === 200 ? 'success' : 'error',
        })
        this.$parent.$parent.$parent.$parent.findAllPointFun()
        this.handleCloseChangeRunState()
      }
    },
  },

  filters: {
    time(v) {
      return v.replace('T', ' ').replace(/\.000\+0800/, '')
    },
  },
  mounted() {
    console.log('runStateList。。。', this.runStateList)
  },
}
